<script setup lang="ts">
import type { NavItem } from '@nuxt/content/dist/runtime/types';

const navigation = inject<Ref<NavItem[]>>('navigation', ref([]));

const { isAuthenticated } = useAuth();
const user = useUser();

const links = [
    // {
    //     label: 'Products',
    //     to: '/products',
    // },
    {
        label: 'Sports',
        to: '/sports',
    },
    //  {
    //   label: 'Pricing',
    //   to: '/pricing'
    // },
    {
        label: 'Our story',
        to: '/about',
    },
];
</script>

<template>
    <header class="flex w-full bg-[#FFFAED] items-center justify-between mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-4">
        <NuxtLink to="/">
            <div class="w-36">
                <CommonLogo />
            </div>
        </NuxtLink>

        <NavigationDesktopMenu class="hidden md:flex" :links="links" />

        <NavigationMobileMenu class="flex md:hidden" :links="links" />
    </header>
</template>
