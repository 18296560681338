<script setup lang="ts">
import { Icon } from '@iconify/vue';
import { ref } from 'vue';
import {
    NavigationMenuContent,
    NavigationMenuIndicator,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuRoot,
    NavigationMenuTrigger,
    NavigationMenuViewport,
} from 'radix-vue';
import NavigationMenuListItem from './NavigationMenuListItem.vue';

const props = defineProps({
  links: null
});

const currentTrigger = ref('');
</script>

<template>
    <NavigationMenuRoot v-model="currentTrigger" class="relative z-[1] flex w-full justify-center">
        <NavigationMenuList class="center m-0 flex list-none rounded-[6px] p-1">
            <NavigationMenuItem>
                <NavigationMenuTrigger
                    class="text-stone-900 hover:bg-yellow-50 group flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]"
                >
                    Tools
                    <Icon
                        icon="radix-icons:caret-down"
                        class="text-green10 relative top-[1px] transition-transform duration-[250ms] ease-in group-data-[state=open]:-rotate-180"
                    />
                </NavigationMenuTrigger>
                <NavigationMenuContent
                    class="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto"
                >
                    <ul class="one m-0 grid list-none gap-x-[10px] p-[22px] sm:w-[500px] sm:grid-cols-[0.75fr_1fr]">
                        <li class="row-span-3 grid">
                            <NavigationMenuLink as-child>
                                <a
                                    class="focus:shadow-green7 from-yellow-100 to-yellow-300 flex h-full w-full select-none flex-col justify-end rounded-[6px] bg-gradient-to-b p-[25px] no-underline outline-none focus:shadow-[0_0_0_2px]"
                                    href="/"
                                >
                                    <img class="w-16" src="https://arenachamp.com/icon.png" />
                                    <div class="mt-4 mb-[7px] text-[18px] font-bold leading-[1.2] text-black">
                                        Arenachamp
                                    </div>
                                    <p class="text-mauve4 text-[14px] leading-[1.3]">
                                        Bring an arena sensation to your sporting events.
                                    </p>
                                </a>
                            </NavigationMenuLink>
                        </li>

                        <NavigationMenuListItem href="/events" title="Events">
                            Create a sporting event, manage team registrations, and more.
                        </NavigationMenuListItem>
                        <NavigationMenuListItem href="/teams" title="Teams">
                            Manage your team, as a coach or a player.
                        </NavigationMenuListItem>
                        <NavigationMenuListItem href="/games" title="Scores">
                            Keep track of your team's scores and stats.
                        </NavigationMenuListItem>
                    </ul>
                </NavigationMenuContent>
            </NavigationMenuItem>

            <NavigationMenuItem>
                <NavigationMenuTrigger
                    class="text-stone-900 hover:bg-yellow-50 focus:shadow-green7 group flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]"
                >
                    Sports
                    <Icon
                        icon="radix-icons:caret-down"
                        class="text-green10 relative top-[1px] transition-transform duration-[250ms] ease-in group-data-[state=open]:-rotate-180"
                    />
                </NavigationMenuTrigger>
                <NavigationMenuContent
                    class="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto"
                >
                    <ul class="one m-0 grid list-none gap-x-[10px] p-[22px] sm:w-[500px] sm:grid-cols-[0.75fr_1fr]">
                        <li class="row-span-3 grid">
                            <NavigationMenuLink as-child>
                                <a
                                    class="focus:shadow-green7 from-yellow-100 to-yellow-300 flex h-full w-full select-none flex-col justify-end rounded-[6px] bg-gradient-to-b p-[25px] no-underline outline-none focus:shadow-[0_0_0_2px]"
                                    href="/"
                                >
                                    <div class="mt-4 mb-[7px] text-sm font-bold leading-[1.2] text-black">
                                        Sports we support
                                    </div>
                                    <p class="text-mauve4 text-xs leading-[1.3]">
                                        The sporting disciplines that are compatible with our scoreboards.
                                    </p>
                                </a>
                            </NavigationMenuLink>
                        </li>

                        <NavigationMenuListItem href="/sports" title="Canoe Polo" icon="i-ic-round-kayaking">
                        </NavigationMenuListItem>
                        <NavigationMenuListItem href="/sports" title="Netball" icon="i-icon-park-outline-volleyball">
                        </NavigationMenuListItem>
                        <NavigationMenuListItem
                            href="/sports"
                            title="See all supported sports"
                            trailingIcon="i-heroicons-arrow-right-16-solid"
                            color="gray"
                        >
                        </NavigationMenuListItem>
                    </ul>
                </NavigationMenuContent>
            </NavigationMenuItem>

            <NavigationMenuItem>
                <NavigationMenuLink
                    as-child
                    class="text-stone-900 hover:bg-yellow-50 focus:shadow-green7 block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none focus:shadow-[0_0_0_2px]"
                >
                    <NuxtLink to="/about"> About </NuxtLink>
                </NavigationMenuLink>
            </NavigationMenuItem>

            <NavigationMenuIndicator
                class="data-[state=hidden]:opacity-0 duration-200 data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[all,transform_250ms_ease]"
            >
                <div class="relative top-[70%] h-[10px] w-[10px] rotate-[45deg] rounded-tl-[2px] bg-white" />
            </NavigationMenuIndicator>
        </NavigationMenuList>

        <div class="perspective-[2000px] absolute top-full left-0 flex w-full justify-center">
            <NavigationMenuViewport
                class="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[10px] bg-white transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]"
            />
        </div>
    </NavigationMenuRoot>
</template>
