<script setup lang="ts">
import { NavigationMenuLink } from 'radix-vue';

const props = defineProps({
    title: String,
    icon: String,
    color: String,
    trailingIcon: String,
});

const $slots = defineSlots();

const hasSlot = !!$slots.default;

const colorClass = computed(() => {
    return props.color ? `bg-${props.color}-100` : 'bg-white';
});
</script>

<template>
    <li>
        <NavigationMenuLink as-child>
            <NuxtLink
                v-bind="$attrs"
                :class="colorClass"
                class="focus:shadow-[0_0_0_2px] hover:bg-yellow-50 select-none rounded-[6px] p-3 text-[15px] leading-none no-underline outline-none transition-colors flex flex-col gap-2"
            >
                <div class="flex justify-between items-center">
                    <div class="text-yellow-900 font-medium leading-[1.2] flex gap-2 items-center">
                        <UIcon v-if="icon" :name="icon" />
                        {{ props.title }}
                    </div>
                    <UIcon v-if="trailingIcon" :name="trailingIcon" />
                </div>
                <p v-if="hasSlot" class="text-mauve11 my-0 leading-[1.4]">
                    <slot />
                </p>
            </NuxtLink>
        </NavigationMenuLink>
    </li>
</template>
